import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import homeImage from './pexels-katerina-holmes-5905440.jpg';
import secondRowImage from './secondrow.png';
import thirdRowImage from './thirdrow.jpg';
import contactusImage from './contactus.jpg';
import indianMomImage from './indianmom.jpg';
import ahmedKhanImage from './ahmedkhan.jpg';
import SofiaGonzalezImage from './sofiagonzalez.jpg';

const useScrollEffect = (direction = 'right', fadeIn = false, maxTranslateX = 100) => {
  const [scrollEffect, setScrollEffect] = useState({ translateX: 0, opacity: 1 });
  const elementRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        const triggerPoint = windowHeight * 0.3;
        const offset = (rect.top - triggerPoint) / windowHeight;

        const translateX = Math.min(
          Math.max(offset * (direction === 'left' ? maxTranslateX : -maxTranslateX), direction === 'left' ? maxTranslateX : -maxTranslateX),
          0
        );

        let opacity = 1;
        if (fadeIn) {
          opacity = Math.min(Math.max(1 - offset * 2, 0), 1);
        }

        setScrollEffect({ translateX, opacity });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [direction, fadeIn, maxTranslateX]);

  return [elementRef, scrollEffect];
};

const useScrollParagraphEffect = () => {
  const [scale, setScale] = useState(1);
  const elementRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        const triggerPoint = windowHeight * 0.6;
        const offset = (rect.top - triggerPoint) / windowHeight;

        const scaleValue = Math.max(1 - Math.abs(offset) * 0.5, 0.5);
        setScale(scaleValue);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return [elementRef, scale];
};

const Home = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isTutor, setIsTutor] = useState(false); // State to track if the user is a tutor
  const [isHovered, setIsHovered] = useState(false);
  const [headerRef, headerEffect] = useScrollEffect('right');
  const [secondRowRef, secondRowEffect] = useScrollEffect('left', false, 50);
  const [thirdRowRef, thirdRowEffect] = useScrollEffect('right', true);
  const [contactUsRef, contactUsEffect] = useScrollEffect('right');

  const [paragraph1Ref, paragraph1Scale] = useScrollParagraphEffect();
  const [paragraph2Ref, paragraph2Scale] = useScrollParagraphEffect();
  const [paragraph3Ref, paragraph3Scale] = useScrollParagraphEffect();

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    const role = localStorage.getItem('userRole'); // Get role from local storage
    const userId = localStorage.getItem('userId'); // Get userId from local storage
    console.log('Token:', token);
    if (token) {
      setIsLoggedIn(true);

      // Determine if the user is a tutor or a customer
      if (role === 'tutor') {
        setIsTutor(true);
        console.log('Tutor ID:', userId);

        axios.get(`${process.env.REACT_APP_API_URL}/api/tutors/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then(response => {
          // Handle tutor data here
          console.log("Tutor data:", response.data);
        }).catch(error => {
          console.error('Error fetching tutor data:', error);
        });

      } else if (role === 'customer') {
        console.log('Customer ID:', userId);

        axios.get(`${process.env.REACT_APP_API_URL}/api/customers/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then(response => {
          // Handle customer data here
          console.log("Customer data:", response.data);
        }).catch(error => {
          console.error('Error fetching customer data:', error);
        });
      }
    }
  }, []);


  const handleGetInTouchClick = () => {
    navigate('/contact'); // Redirect to the Contact Us page
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleRequestTutorClick = () => {
    if (isTutor) {
      navigate('/view-sessions'); // Redirect to view available sessions if user is a tutor
    } else if (isLoggedIn) {
      navigate('/request'); // Redirect to request a tutor if user is logged in
    } else {
      navigate('/login'); // Redirect to login if user is not logged in
    }
  };

  return (
    <div style={{
      fontFamily: 'DM Serif Display, serif',
      backgroundColor: '#a19d91',
      textAlign: 'center',
      position: 'relative',
      overflowX: 'hidden',
      minHeight: '100vh',
    }}>
      <header>
        {isLoggedIn && (
          <button 
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              padding: '10px 20px',
              fontSize: 'calc(1rem + 0.5vw)',
              color: '#ffffff',
              backgroundColor: '#1b2625',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer',
              textShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
              fontWeight: 'bold',
            }}
          >
            Logout
          </button>
        )}
      </header>

      <main style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        position: 'relative',
        zIndex: 1,
        paddingBottom: '30px',
      }}>

        {/* Home Image */}
        <div style={{
          width: '100%',
          position: 'relative',
          zIndex: 0,
        }}>
          <img src={homeImage} alt="Tutor and Student" style={{
            width: '100%',
            height: 'auto',
            minHeight: '50vh',  // Ensure a minimum height on all screens
            objectFit: 'cover',
            display: 'block',
          }} />

          {/* Header Content (FIND A TUTOR Button) */}
          <button
  ref={headerRef}
  onMouseEnter={handleMouseEnter}
  onMouseLeave={handleMouseLeave}
  onClick={handleRequestTutorClick}
  style={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%) translateX(${headerEffect.translateX}%) ${isHovered ? 'scale(1.1)' : 'scale(1)'}`,
    opacity: headerEffect.opacity,
    zIndex: 2,
    transition: 'transform 0.3s ease-out, opacity 0.3s ease-out, background-color 0.3s, box-shadow 0.3s',
    color: '#ffffff',
    backgroundColor: isHovered ? '#283d3b' : '#1b2625',
    width: '40%', // Increase the button width to 40% of the page
    padding: '15px 0', // Adjust padding for consistent height
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    textShadow: isHovered ? '0px 0px 20px rgba(255, 255, 255, 0.5)' : '0px 0px 10px rgba(0, 0, 0, 0.3)',
    boxShadow: isHovered ? '0px 0px 25px rgba(0, 0, 0, 0.7)' : '0px 0px 15px rgba(0, 0, 0, 0.5)',
    fontWeight: '100',
    textTransform: 'uppercase',
    outline: 'none',
    fontSize: '3vw', // Increase the font size for larger screens
  }}
>
  {isTutor ? 'VIEW AVAILABLE SESSIONS' : 'REQUEST A TUTOR'}
</button>

<style>
  {`
    @media (max-width: 768px) {
      img {
        height: 60vh; /* Increase height on mobile */
      }
      button {
        font-size: 8vw; /* Adjust font size for smaller screens */
        width: 70%; /* Adjust button width for smaller screens */
      }
    }
  `}
</style>
        </div>

        {/* Second Row Content */}
        <div ref={secondRowRef} style={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          height: '100%',
          opacity: secondRowEffect.opacity,
          transform: `translateX(${secondRowEffect.translateX}%)`,
          transition: 'transform 0.5s ease-out, opacity 0.5s ease-out',
        }}>
          {/* Left Image */}
          <div style={{
            width: '100%',
            height: '100%',
          }}>
            <img src={secondRowImage} alt="Additional Content" style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }} />
          </div>

          {/* Right Text */}
          <div style={{
            width: '100%',
            height: '100%',
            padding: '20px',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'left',
            fontSize: 'calc(1vw + 1vh + 1vmin)',
            lineHeight: '1.5',
          }}>
            <div>
              <h2 style={{ transform: `scale(${paragraph1Scale})`, fontSize: 'calc(3.5vw + 1.5vh + 1vmin)', marginBottom: '20px' }}>Our Process</h2>
              <p ref={paragraph1Ref} style={{ transform: `scale(${paragraph1Scale})`, transition: 'transform 0.3s ease-out' }}>
                <strong>Foundational Learning for Children:</strong> We begin by teaching your children the fundamental concepts of each subject, helping them understand the different types of challenges they might encounter.
              </p>
              <p ref={paragraph2Ref} style={{ transform: `scale(${paragraph2Scale})`, transition: 'transform 0.3s ease-out' }}>
                <strong>Empowering Parents:</strong> We guide parents on how to effectively use online tools to support and teach their children, ensuring they can actively participate in their child's education.
              </p>
              <p ref={paragraph3Ref} style={{ transform: `scale(${paragraph3Scale})`, transition: 'transform 0.3s ease-out' }}>
                <strong>Homework Helpline:</strong> We offer a subscription based helpline where students can call at any time to get homework help, ensuring that children have support whenever needed.
              </p>
            </div>
          </div>
        </div>

        {/* Third Row: Testimonials */}
        <div ref={thirdRowRef} style={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '0px', // Remove any gap between columns
          height: '100%',
          opacity: thirdRowEffect.opacity,
          transform: `translateX(${thirdRowEffect.translateX}%)`,
          transition: 'transform 0.3s ease-out, opacity 0.3s ease-out',
          backgroundColor: '#a19d91',
          padding: '0px', // Remove padding that could affect column sizes
        }}>
          {/* Left Side: Testimonials */}
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            padding: '10px 10px', // Adjust padding to ensure consistent spacing
            backgroundColor: '#a19d91',
          }}>
            {/* First Testimonial */}
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
              marginBottom: '20px',
              padding: '20px',
              backgroundColor: '#bcb1a2',
              borderRadius: '8px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}>
                <img src={ahmedKhanImage} alt="Ahmed Khan" style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  marginRight: '15px',
                }} />
                <div style={{ flexGrow: 1 }}>
                  <strong style={{ display: 'block', textAlign: 'left' }}>Ahmed Khan</strong>
                  <span style={{ display: 'block', color: '#FFD700', textAlign: 'left' }}>★★★★★</span>
                </div>
              </div>
              <blockquote style={{
                margin: '15px 0 0 0',
                fontSize: 'calc(0.9rem + 0.5vw)',
                lineHeight: '1.5',
                fontStyle: 'italic',
                width: '100%', 
              }}>
                "This service has been a game-changer for our family. Our children are more confident in their studies, and we, as parents, feel empowered to help them succeed."
              </blockquote>
              <small style={{ color: '#5E5E5E', marginTop: '10px', display: 'block', width: '100%' }}>
                Reviewed on August 18, 2024
              </small>
            </div>

            {/* Second Testimonial */}
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
              marginBottom: '20px',
              padding: '20px',
              backgroundColor: '#bcb1a2',
              borderRadius: '8px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}>
                <img src={SofiaGonzalezImage} alt="Sofia Gonzalez" style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  marginRight: '15px',
                }} />
                <div style={{ flexGrow: 1 }}>
                  <strong style={{ display: 'block', textAlign: 'left' }}>Sofia Gonzalez</strong>
                  <span style={{ display: 'block', color: '#FFD700', textAlign: 'left' }}>★★★★★</span>
                </div>
              </div>
              <blockquote style={{
                margin: '15px 0 0 0',
                fontSize: 'calc(0.9rem + 0.5vw)',
                lineHeight: '1.5',
                fontStyle: 'italic',
                width: '100%', 
              }}>
                "I can't thank the team enough for their support. My child is thriving, and I feel more confident in helping them succeed."
              </blockquote>
              <small style={{ color: '#5E5E5E', marginTop: '10px', display: 'block', width: '100%' }}>
                Reviewed on March 12, 2024
              </small>
            </div>

            {/* Third Testimonial */}
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
              marginBottom: '20px',
              padding: '20px',
              backgroundColor: '#bcb1a2',
              borderRadius: '8px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}>
                <img src={indianMomImage} alt="Anjali Sharma" style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  marginRight: '15px',
                }} />
                <div style={{ flexGrow: 1 }}>
                  <strong style={{ display: 'block', textAlign: 'left' }}>Anjali Sharma</strong>
                  <span style={{ display: 'block', color: '#FFD700', textAlign: 'left' }}>★★★★★</span>
                </div>
              </div>
              <blockquote style={{
                margin: '15px 0 0 0',
                fontSize: 'calc(0.9rem + 0.5vw)',
                lineHeight: '1.5',
                fontStyle: 'italic',
                width: '100%', 
              }}>
                "The focus on empowering parents is what sets this program apart. We're now able to support our child in ways we never thought possible."
              </blockquote>
              <small style={{ color: '#5E5E5E', marginTop: '10px', display: 'block', width: '100%' }}>
                Reviewed on July 21, 2023
              </small>
            </div>

          </div>

          {/* Right Side: Image */}
          <div style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden', // Ensure the image does not overflow its container
          }}>
            <img src={thirdRowImage} alt="Additional Content" style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }} />
          </div>
        </div>

        {/* Contact Us Row */}
        <div ref={contactUsRef} style={{
          width: '100%',
          position: 'relative',
          height: '400px',
          opacity: contactUsEffect.opacity,
          transform: `translateX(${contactUsEffect.translateX}%)`,
          transition: 'transform 0.3s ease-out, opacity 0.3s ease-out',
        }}>
          <img src={contactusImage} alt="Contact Us" style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            filter: 'brightness(70%)',
          }} />
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            textAlign: 'center',
            padding: '20px',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            borderRadius: '10px',
          }}>
            <h2 style={{
              fontSize: 'calc(2vw + 2vh)',
              marginBottom: '10px',
              fontWeight: 'bold',
              letterSpacing: '1px',
            }}>Contact Us</h2>
            <p style={{
              fontSize: 'calc(1vw + 1vh)',
              lineHeight: '1.5',
              margin: '0',
            }}>
              We're here to help. Reach out to us with any questions or inquiries. Our team is ready to assist you.
            </p>
            <button 
              onClick={() => navigate('/contact')} // Navigate to the Contact Us page
              style={{
                marginTop: '20px',
                padding: '10px 20px',
                fontSize: 'calc(1rem + 1vw)',
                color: '#ffffff',
                backgroundColor: '#1b2625',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
                textShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                fontWeight: 'bold',
              }}>
              Get In Touch
            </button>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
