import React, { useState } from 'react';
import request1 from './request1.jpg';
import request2 from './request2.jpg';
import axios from 'axios';

const TutorRequestForm = () => {
  const [formData, setFormData] = useState({
    subject: '',
    date: '',
    location: '',
    startTime: '',
    endTime: '',
    nativeLanguage: '',
    educationLevel: '', // Added Education Level
    serviceType: 'Child Tutoring', // Default value for serviceType
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

const handleSubmit = async (e) => {
  e.preventDefault();

  // Retrieve the customerId from localStorage
  const customerId = localStorage.getItem('userId'); // Retrieve the customer ID

  if (!customerId) {
    console.error('No customer ID found in localStorage.');
    alert('You need to log in before creating a request.');
    return;
  }

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/requests`, {
      ...formData,
      customerId: customerId, // Ensure this is a valid ObjectId string
      isTutorMatched: false, // Default to false; this will be updated later by the backend
    });
    console.log('Request submitted:', response.data);
    setIsSubmitted(true);
  } catch (error) {
    console.error('There was an error submitting the request!');

    if (error.response) {
      console.error('Error response data:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    } else if (error.request) {
      console.error('No response received. Request was:', error.request);
    } else {
      console.error('Error in setting up the request:', error.message);
    }

    console.error('Error config:', error.config);
  }
};


  return (
    <div style={{
      fontFamily: 'DM Serif Display, serif',
      backgroundColor: '#a19d91',
      textAlign: 'center',
      position: 'relative',
      overflowX: 'hidden',
      minHeight: '100vh',
      padding: '70px 20px',
    }}>
      {!isSubmitted ? (
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <img src={request1} alt="Request 1" style={{
            flexGrow: 1,
            height: 'auto',
            marginRight: '20px',
            borderRadius: '8px',
            boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
            maxWidth: 'calc(50% - 200px)', // Ensures image doesn't get too wide on large screens
          }} />
          <form onSubmit={handleSubmit} style={{
            maxWidth: '600px',
            margin: '0 auto',
            backgroundColor: '#ffffff',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
          }}>
            <h2 style={{
              marginBottom: '20px',
              fontSize: 'calc(1.5rem + 1vw)',
              color: '#333333',
            }}>Request a Tutor</h2>

            {/* Service Type Selection */}
            <div style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', textAlign: 'left', marginBottom: '5px' }}>Service Type:</label>
              <select
                name="serviceType"
                value={formData.serviceType}
                onChange={handleChange}
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                }}
                required
              >
                <option value="Child Tutoring">Child Tutoring</option>
                <option value="Parent Tutoring">Parent Tutoring</option>
              </select>
            </div>

            <div style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', textAlign: 'left', marginBottom: '5px' }}>Subject:</label>
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                }}
                required
              />
            </div>

            <div style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', textAlign: 'left', marginBottom: '5px' }}>Date:</label>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                }}
                required
              />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
              <div style={{ width: '48%' }}>
                <label style={{ display: 'block', textAlign: 'left', marginBottom: '5px' }}>Start Time:</label>
                <input
                  type="time"
                  name="startTime"
                  value={formData.startTime}
                  onChange={handleChange}
                  style={{
                    width: '100%',
                    padding: '10px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                  }}
                  required
                />
              </div>

              <div style={{ width: '48%' }}>
                <label style={{ display: 'block', textAlign: 'left', marginBottom: '5px' }}>End Time:</label>
                <input
                  type="time"
                  name="endTime"
                  value={formData.endTime}
                  onChange={handleChange}
                  style={{
                    width: '100%',
                    padding: '10px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                  }}
                  required
                />
              </div>
            </div>

            <div style={{ marginBottom: '15px' }}>
              <label style={{ display: 'block', textAlign: 'left', marginBottom: '5px' }}>Location:</label>
              <input
                type="text"
                name="location"
                value={formData.location}
                onChange={handleChange}
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                }}
                required
              />
            </div>

            <div style={{ marginBottom: '20px' }}>
              <label style={{ display: 'block', textAlign: 'left', marginBottom: '5px' }}>Native Language:</label>
              <input
                type="text"
                name="nativeLanguage"
                value={formData.nativeLanguage}
                onChange={handleChange}
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                }}
                required
              />
            </div>

            {/* Education Level Selection */}
            <div style={{ marginBottom: '20px' }}>
              <label style={{ display: 'block', textAlign: 'left', marginBottom: '5px' }}>Education Level:</label>
              <select
                name="educationLevel"
                value={formData.educationLevel}
                onChange={handleChange}
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                }}
                required
              >
                <option value="">Select Education Level</option>
                <option value="Elementary">Elementary</option>
                <option value="Middle School">Middle School</option>
                <option value="High School">High School</option>
                <option value="Undergraduate">Undergraduate</option>
                <option value="Postgraduate">Postgraduate</option>
              </select>
            </div>

            <button type="submit" style={{
              width: '100%',
              padding: '10px',
              fontSize: 'calc(1rem + 0.5vw)',
              color: '#ffffff',
              backgroundColor: '#1b2625',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
            }}>
              Submit Request
            </button>
          </form>
          <img src={request2} alt="Request 2" style={{
            flexGrow: 1,
            height: 'auto',
            marginLeft: '20px',
            borderRadius: '8px',
            boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
            maxWidth: 'calc(50% - 200px)', // Ensures image doesn't get too wide on large screens
          }} />
        </div>
      ) : (
        <div style={{
          maxWidth: '600px',
          margin: '0 auto',
          backgroundColor: '#ffffff',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
          textAlign: 'center',
          animation: 'fadeIn 1s ease-out',
        }}>
          <h2 style={{
            marginBottom: '20px',
            fontSize: 'calc(1.5rem + 1vw)',
            color: '#333333',
          }}>Thank You!</h2>
          <p style={{
            fontSize: 'calc(1rem + 0.5vw)',
            color: '#555555',
          }}>Your tutor request has been submitted successfully.</p>
          <p style={{
            fontSize: 'calc(1rem + 0.5vw)',
            color: '#555555',
          }}>A confirmation email has been sent to you. A confirmation of your tutor will be sent shortly!</p>
        </div>
      )}
    </div>
  );
};

const Home = () => {
  return (
    <div style={{
      fontFamily: 'DM Serif Display, serif',
      backgroundColor: '#a19d91',
      textAlign: 'center',
      position: 'relative',
      overflowX: 'hidden',
      minHeight: '100vh',
    }}>
      <main style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        position: 'relative',
        zIndex: 1,
        paddingBottom: '30px',
      }}>

        {/* Tutor Request Form */}
        <TutorRequestForm />

      </main>
    </div>
  );
}

export default Home;
